import { Button, Checkbox, notification } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSearchParams from '../../hooks/useSearchParams'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { eCommerceOrderTabs, orderStatuses, LocationTypes, sources as sourcesOptions, PartnerType, deliveryOrderStatusOptions, invoicePrintStatusOptions, dateTimeDisplayFormats, ecommerceIndustryType, roles } from '../../utils/constants'
import CustomSelectSecondary from '../custom-select-secondary'
import Input from '../input'
import styles from './EcommerceSalesOrderFilter.module.css'
import DropdownOverlay from '../dropdown-overlay'
import DeliveryPartnerOption from '../delivery-partner-option'
import { GlobalFilterContext } from '../../contexts/GlobalFilter'
import CustomSearchableSelectSecondary from '../custom-searchable-select-secondary'
import { Locations } from '../../services/api/locations'
import { convertEnumToString } from '../../utils'
import NumberInput from '../number-input'
import { Users } from '../../services/api/users'
import { SalesOrders } from '../../services/api/sales-orders'
import { useRouter } from 'next/router'
import CustomSearchableSelectWithTags from '../custom-searchable-select-with-tags'
import { fetchCustomerTags } from '../../store/distributors/actions'

const availableAndUnavailableOptions = [
	{
		label: 'Available',
		value: 'available'
	},
	{
		label: 'Unavailable',
		value: 'unavailable'
	}
]

const orderStatusOptions = [
	{ label: 'Created', value: orderStatuses.SALES_ORDER_REQUESTED_STATUS },
	{ label: 'Approved', value: orderStatuses.SALES_ORDER_APPROVED_STATUS },
	{ label: 'Processing', value: orderStatuses.SALES_ORDER_PROCESSING_STATUS },
	{ label: 'Shipped', value: orderStatuses.SALES_ORDER_SHIPPED_STATUS },
	{ label: 'In-Transit', value: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS },
	{ label: 'Delivered', value: orderStatuses.SALES_ORDER_COMPLETED_STATUS },
	{ label: 'Cancelled', value: orderStatuses.SALES_ORDER_CANCELLED_STATUS },
	{ label: 'Flagged', value: orderStatuses.SALES_ORDER_FLAGGED_STATUS }
]

const eCommerceStatusOptions = [
	{ label: 'On Hold', value: orderStatuses.SALES_ORDER_ON_HOLD_STATUS },
	{ label: 'Approved', value: orderStatuses.SALES_ORDER_APPROVED_STATUS },
	{ label: 'Processing', value: orderStatuses.SALES_ORDER_PROCESSING_STATUS },
	{ label: 'Shipped', value: orderStatuses.SALES_ORDER_SHIPPED_STATUS },
	{ label: 'In-Transit', value: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS },
	{ label: 'Delivered -> Payment Due', value: orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS },
	{ label: 'Delivered -> Payment Collected', value: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS },
	{ label: 'Flagged -> Returned', value: orderStatuses.SALES_ORDER_RETURNED_STATUS },
	{ label: 'Flagged -> Damaged', value: orderStatuses.SALES_ORDER_DAMAGED_STATUS },
	{ label: 'Cancelled', value: orderStatuses.SALES_ORDER_CANCELLED_STATUS }
]

const EcommerceSalesOrderFilter = ({
	isFilterDropdownOpen,
	setIsFilterDropdownOpen,
	onFilter
}) => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { followUpDate } = router.query
	const shouldResetFilter = useContext(GlobalFilterContext)
	const { enabledOrderStatuses, preferences, permissions, companyDetails } = useSelector(state => state.authReducer)
	const { searchParams, applyFilter, clearFilter } = useSearchParams()
	const modifiedECommerceOrderTabs = useMemo(() => {
		const tabs = eCommerceOrderTabs.slice(1)
		return tabs.filter(tab => enabledOrderStatuses[tab.status])
	}, [enabledOrderStatuses])
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [statuses, setStatuses] = useState(searchParams.statuses || [])
	const [sources, setSources] = useState(searchParams.sources || [])
	const [creationFromDate, setCreationFromDate] = useState(searchParams.creationFromDate)
	const [creationToDate, setCreationToDate] = useState(searchParams.creationToDate)
	const [shippingFromDate, setShippingFromDate] = useState(searchParams.shippingFromDate)
	const [shippingToDate, setShippingToDate] = useState(searchParams.shippingToDate)
	const [transitionFromDate, setTransitionFromDate] = useState(searchParams.statusTransitionFromDate)
	const [transitionToDate, setTransitionToDate] = useState(searchParams.statusTransitionToDate)
	const [transitionStatuses, setTransitionStatuses] = useState(searchParams.transitionStatuses || [])
	const [followUpFromDate, setFollowUpFromDate] = useState(searchParams.followUpFromDate)
	const [followUpToDate, setFollowUpToDate] = useState(searchParams.followUpToDate)
	const [minOrderValue, setMinOrderValue] = useState(searchParams.minOrderValue)
	const [maxOrderValue, setMaxOrderValue] = useState(searchParams.maxOrderValue)
	const [minProductCount, setMinProductCount] = useState(searchParams.minProductCount)
	const [maxProductCount, setMaxProductCount] = useState(searchParams.maxProductCount)
	const [startingInvoice, setStartingInvoice] = useState(searchParams.startingInvoice)
	const [endingInvoice, setEndingInvoice] = useState(searchParams.endingInvoice)
	const [preferredDeliveryPartner, setPreferredDeliveryPartner] = useState(searchParams.preferredDeliveryPartner || null)
	const [selectedInternalNotes, setSelectedInternalNotes] = useState(
		searchParams.selectedInternalNotes ? [searchParams.selectedInternalNotes] : []
	)
	const [selectedDiscounts, setSelectedDiscounts] = useState(searchParams.selectedDiscounts ? [searchParams.selectedDiscounts] : [])
	const [selectedUpsells, setSelectedUpsells] = useState(searchParams.selectedUpsells ? [searchParams.selectedUpsells] : [])
	const [selectedAdvancePayments, setSelectedAdvancePayments] = useState(searchParams.selectedAdvancePayments ? [searchParams.selectedAdvancePayments] : [])
	const [invoicePrintStatus, setInvoicePrintStatus] = useState(searchParams.invoicePrintStatus ? [searchParams.invoicePrintStatus] : [])
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [isLoadingBranchLocations, setIsLoadingBranchLocations] = useState(false)
	const [locations, setLocations] = useState([])
	const [location, setLocation] = useState(null)
	const [branchLocations, setBranchLocations] = useState([])
	const [branchLocation, setBranchLocation] = useState(null)
	const [deliveryOrderStatus, setDeliveryOrderStatus] = useState(null)
	const [areAllDeliveryOrderStatusesSelected, setAreAllDeliveryOrderStatusesSelected] = useState(false)
	const [selectedDeliveryPartnerStatuses, setSelectedDeliveryPartnerStatuses] = useState([])
	const [orderStatus, setOrderStatus] = useState(orderStatusOptions[0].value)
	const [isLoadingUsers, setIsLoadingUsers] = useState(false)
	const [users, setUsers] = useState([])
	const [user, setUser] = useState(null)
	const [referrers, setReferrers] = useState([])
	const [referrer, setReferrer] = useState(searchParams.referrerId)
	const [isLoadingSourceUrls, setIsLoadingSourceUrls] = useState(false)
	const [sourceUrls, setSourceUrls] = useState([])
	const [sourceUrl, setSourceUrl] = useState(null)
	const [areAllOrderStatusSelected, setAreAllOrderStatusSelected] = useState(false)
	const [tag, setTag] = useState(searchParams.customerTagId)
	const [isCustomerTagOptionsLoading, setIsCustomerTagOptionsLoading] = useState(false)
	const [customerTagOptions, setCustomerTagOptions] = useState([])
	const [areAllCustomerTagsSelected, setAreAllCustomerTagsSelected] = useState(false)
	const [selectedCustomerTags, setSelectedCustomerTags] = useState([])
	const deliveryPartneStatusOptions = useMemo(() => {
		if (!preferredDeliveryPartner || !deliveryPartners?.length || isLoadingDeliveryPartners) {
			return []
		}
		const deliveryPartner = deliveryPartners.find(({ value }) => value === preferredDeliveryPartner)
		switch (deliveryPartner?.type) {
			case PartnerType.MOVEX:
			case PartnerType.STEADFAST:
			case PartnerType.PATHAO:
			case PartnerType.PIDEX:
			case PartnerType.REDX:
			case PartnerType.E_COURIER:
			case PartnerType.POST_EX:
			case PartnerType.MP_COURIER:
			case PartnerType.LEOPARDS:
			case PartnerType.BLUE_EX:
			case PartnerType.PAPERFLY:
			case PartnerType.PANDAGO:
			case PartnerType.XPRESS_IN_TOWN:
				return deliveryOrderStatusOptions[deliveryPartner.type]
			default:
				return []
		}
	}, [preferredDeliveryPartner, deliveryPartners, isLoadingDeliveryPartners])
	const isDateTimeFormat = preferences?.dateTimeDisplayFormat === dateTimeDisplayFormats.DATE_TIME
	const timeFormat = isDateTimeFormat ? 'YYYY-MM-DD h:mm A' : 'YYYY-MM-DD'

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		getDeliveryPartners()
		getWarehouses()
		getBranches()
		getSourceUrls()
	}, [isFilterDropdownOpen])

	useEffect(() => {
		if (!isFilterDropdownOpen) {
			return
		}
		if (permissions.viewCustomerTags) {
			getCustomerTags()
		}
	}, [isFilterDropdownOpen, permissions])

	useEffect(() => {
		if (!orderStatus) {
			return
		}
		getUsers()
	}, [orderStatus])

	useEffect(() => {
		if (!shouldResetFilter) {
			return
		}
		resetFilter()
	}, [shouldResetFilter])

	useEffect(() => {
		if (followUpDate) {
			const filters = {}
			const followUpFromDate = moment(followUpDate).startOf('day').toDate()
			const followUpToDate = moment(followUpDate).endOf('day').toDate()
			setFollowUpFromDate(followUpFromDate)
			setFollowUpToDate(followUpToDate)
			setStatuses([orderStatuses.SALES_ORDER_ON_HOLD_STATUS])

			filters.followUpFromDate = followUpFromDate
			filters.followUpToDate = followUpToDate
			filters.statuses = [orderStatuses.SALES_ORDER_ON_HOLD_STATUS]
			const query = { ...router.query }
			delete query['followUpDate']
			router.push({ pathname: router.pathname, query })
			applyFilter(filters)
			setIsFilterDropdownOpen(false)
		}
	}, [followUpDate])

	const getCustomerTags = async () => {
		setIsCustomerTagOptionsLoading(true)
		try {
			const customerTags = await dispatch(fetchCustomerTags({ includeDefaultTags: true }))
			const customerTagOptions = customerTags?.map(({ id, name }) => ({ value: id, label: name })) || []
			setCustomerTagOptions(customerTagOptions)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Customer Tags',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCustomerTagOptionsLoading(false)
		}
	}

	const getDeliveryPartners = async () => {
		setIsLoadingDeliveryPartners(true)
		try {
			const { data: { results } } = await DeliveryPartners.index()
			const deliveryPartnersOption = results.map(({ id, name, type }) => ({ label: name, type, value: id }))
			setDeliveryPartners([{ label: 'Unassigned', value: '' }, ...deliveryPartnersOption])
		} catch (e) {
			notification.error({
				message: 'Couldn\'t get delivery partners',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDeliveryPartners(false)
		}
	}

	const handleApply = () => {
		const filters = {}

		if (creationFromDate && creationToDate) {
			if (isDateTimeFormat) {
				filters.creationFromDate = creationFromDate
				filters.creationToDate = creationToDate
			} else {
				filters.creationFromDate = moment(creationFromDate).startOf('day').toDate()
				filters.creationToDate = moment(creationToDate).endOf('day').toDate()
			}
		}
		if (shippingFromDate && shippingToDate) {
			if (isDateTimeFormat) {
				filters.shippingFromDate = shippingFromDate
				filters.shippingToDate = shippingToDate
			} else {
				filters.shippingFromDate = moment(shippingFromDate).startOf('day').toDate()
				filters.shippingToDate = moment(shippingToDate).endOf('day').toDate()
			}
		}
		if (followUpFromDate && followUpToDate) {
			if (isDateTimeFormat) {
				filters.followUpFromDate = followUpFromDate
				filters.followUpToDate = followUpToDate
			} else {
				filters.followUpFromDate = moment(followUpFromDate).startOf('day').toDate()
				filters.followUpToDate = moment(followUpToDate).endOf('day').toDate()
			}
		}
		if (transitionFromDate && transitionToDate && transitionStatuses.length > 0) {
			if (isDateTimeFormat) {
				filters.statusTransitionFromDate = transitionFromDate
				filters.statusTransitionToDate = transitionToDate
			} else {
				filters.statusTransitionFromDate = moment(transitionFromDate).startOf('day').toDate()
				filters.statusTransitionToDate = moment(transitionToDate).endOf('day').toDate()
			}
			filters.transitionStatuses = transitionStatuses
		}
		if (!isNaN(minOrderValue) && !isNaN(maxOrderValue)) {
			filters.minOrderValue = minOrderValue
			filters.maxOrderValue = maxOrderValue
		}
		if (!isNaN(minProductCount) || !isNaN(maxProductCount)) {
			if (!isNaN(minProductCount) && isNaN(maxProductCount)) {
				filters.minProductCount = minProductCount
				filters.maxProductCount = minProductCount
			} else {
				filters.minProductCount = minProductCount
				filters.maxProductCount = maxProductCount
			}
		}
		if (invoicePrintStatus.length === 1) {
			filters.invoicePrintStatus = invoicePrintStatus[0]
		}
		if (!isNaN(startingInvoice) && !isNaN(endingInvoice)) {
			if (startingInvoice > endingInvoice) {
				setEndingInvoice(startingInvoice)
			}
			filters.startingInvoice = startingInvoice
			filters.endingInvoice = endingInvoice
		}
		if (preferredDeliveryPartner !== undefined) {
			filters.preferredDeliveryPartner = preferredDeliveryPartner
		}
		if (location?.data?.id) {
			filters.pickUpLocationIds = [location?.data?.id]
		}
		if (branchLocation?.data?.id) {
			filters.branchLocationIds = [branchLocation?.data?.id]
		}
		if (selectedInternalNotes && selectedInternalNotes.length === 1) {
			filters.selectedInternalNotes = selectedInternalNotes[0]
		}
		if (selectedDiscounts && selectedDiscounts.length === 1) {
			filters.selectedDiscounts = selectedDiscounts[0]
		}
		if (selectedUpsells && selectedUpsells.length === 1) {
			filters.selectedUpsells = selectedUpsells[0]
		}
		if (selectedAdvancePayments && selectedAdvancePayments.length === 1) {
			filters.selectedAdvancePayments = selectedAdvancePayments[0]
		}
		if (statuses.length) {
			filters.statuses = statuses
		}
		if (sources.length) {
			filters.sources = sources
		}
		if (sourceUrl) {
			filters.sourceUrl = sourceUrl.value
		}
		if (selectedDeliveryPartnerStatuses.length > 0) {
			filters.deliveryStatuses = selectedDeliveryPartnerStatuses.map(({ value }) => value)
		}
		if (orderStatus) {
			filters.statusUpdate = orderStatus
		}
		if (user) {
			filters.statusUpdateUserId = user
		}
		if (referrer) {
			filters.referrerId = referrer
		}
		if (selectedCustomerTags.length > 0) {
			filters.customerTagIds = selectedCustomerTags.map(({ value }) => value)
		}
		applyFilter(filters)
		setIsFilterDropdownOpen(false)
		onFilter?.(filters)
	}

	const isFilterEnabled = () => {
		return searchTerm ||
			statuses.length > 0 ||
			creationFromDate ||
			creationToDate ||
			shippingFromDate ||
			shippingToDate ||
			followUpFromDate ||
			followUpToDate ||
			minOrderValue ||
			maxOrderValue ||
			(startingInvoice && endingInvoice) ||
			minProductCount ||
			maxProductCount ||
			preferredDeliveryPartner ||
			preferredDeliveryPartner === '' ||
			location ||
			branchLocation ||
			selectedInternalNotes.length > 0 ||
			selectedDiscounts.length > 0 ||
            selectedUpsells.length > 0 ||
			selectedAdvancePayments.length > 0 ||
			sources.length > 0 ||
			selectedDeliveryPartnerStatuses.length > 0 ||
			user ||
			invoicePrintStatus.length > 0 ||
			(transitionFromDate && transitionToDate && transitionStatuses.length > 0) ||
            selectedCustomerTags.length > 0 ||
			referrer
	}

	const resetFilter = () => {
		setSearchTerm('')
		setCreationFromDate(null)
		setCreationToDate(null)
		setShippingFromDate(null)
		setShippingToDate(null)
		setFollowUpFromDate(null)
		setFollowUpToDate(null)
		setMinOrderValue()
		setMaxOrderValue()
		setMinProductCount()
		setMaxProductCount()
		setInvoicePrintStatus([])
		setStartingInvoice()
		setEndingInvoice()
		setStatuses([])
		setSources([])
		setPreferredDeliveryPartner(null)
		setSelectedInternalNotes([])
		setSelectedDiscounts([])
		setSelectedUpsells([])
		setSelectedAdvancePayments([])
		setLocation(null)
		setBranchLocation(null)
		setDeliveryOrderStatus(null)
		setSelectedDeliveryPartnerStatuses([])
		setAreAllDeliveryOrderStatusesSelected(false)
		setUser(null)
		setSourceUrl(null)
		setTransitionFromDate(null)
		setTransitionToDate(null)
		setTransitionStatuses([])
		setTag(null)
		setSelectedCustomerTags([])
		setAreAllCustomerTagsSelected(false)
		setReferrer(null)
		setUser(null)
		clearFilter()
	}

	const searchLocations = async searchTerm => {
		return await Locations.index({ searchTerm, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.PICKUP], includeDeleted: true })
	}

	const getWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY, LocationTypes.PICKUP], includeDeleted: true })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const searchBranchLocations = async searchTerm => {
		return await Locations.index({ searchTerm, type: LocationTypes.BRANCH, includeDeleted: true })
	}

	const getBranches = async () => {
		setIsLoadingBranchLocations(true)
		const response = await Locations.index({ type: LocationTypes.BRANCH, includeDeleted: true })
		if (response.data) {
			setBranchLocations(response.data.results)
		}
		setIsLoadingBranchLocations(false)
	}

	const getUsers = async () => {
		setIsLoadingUsers(true)
		const response = await Users.index({ page: -1 })
		if (response.data) {
			const users = response.data.results
			const referrers = []
			const filteredUsers = []
			users.forEach(user => {
				const userOption = {
					label: user.name,
					value: user.id,
					locations: user.locations
				}
				if (user.role === roles.DOCTOR) {
					referrers.push(userOption)
				} else {
					filteredUsers.push(userOption)
				}
			})
			setUsers(filteredUsers)
			setReferrers(referrers)
		}
		setIsLoadingUsers(false)
	}

	const getSourceUrls = async () => {
		setIsLoadingSourceUrls(true)
		try {
			const { data } = await SalesOrders.getSourceUrls()
			setSourceUrls(data)
		} catch (e) {
			notification.error({
				message: 'Unable to Get Source URLs',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingSourceUrls(false)
		}
	}

	const searchSourceUrls = async searchTerm => {
		const formattedSearchTerm = searchTerm?.trim()?.toLowerCase() || ''
		if (!formattedSearchTerm) {
			return { data: sourceUrls }
		}
		const filteredSourceUrls = sourceUrls.filter(({ sourceUrl }) =>
			sourceUrl.toLowerCase().includes(formattedSearchTerm)
		)
		return { data: filteredSourceUrls }
	}

	const onDeliveryPartnerStatusSelected = value => {
		const { data } = value
		setDeliveryOrderStatus(value)
		let newDeliveryPartnerStatuses = [...selectedDeliveryPartnerStatuses]
		const existingDeliveryPartnerStatus = selectedDeliveryPartnerStatuses.find(status => status.value === data.value)
		if (!existingDeliveryPartnerStatus) {
			newDeliveryPartnerStatuses.push(data)
		} else {
			newDeliveryPartnerStatuses.splice(newDeliveryPartnerStatuses.indexOf(existingDeliveryPartnerStatus), 1)
		}
		setSelectedDeliveryPartnerStatuses(newDeliveryPartnerStatuses)
	}

	const onCustomerTagSelected = value => {
		const { data } = value
		setTag(value)
		let newCustomerTags = [...selectedCustomerTags]
		const existingCustomerTag = selectedCustomerTags.find(tag => tag.value === data.value)
		if (!existingCustomerTag) {
			newCustomerTags.push(data)
		} else {
			newCustomerTags.splice(newCustomerTags.indexOf(existingCustomerTag), 1)
		}
		setSelectedCustomerTags(newCustomerTags)
	}

	return (
		<DropdownOverlay className='dropdown-overlay'>
			<div>
				<p className={styles.title}>Status</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={statuses}
					onChange={e => setStatuses(e)}
				>
					{
						modifiedECommerceOrderTabs.map((tab) => {
							return (
								<Checkbox
									key={tab.status}
									className={styles.inputTitle}
									value={tab.status}
								>
									{tab.title}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Sources</p>
				<Checkbox.Group
					className={styles.inputGroup}
					value={sources}
					onChange={sources => {
						setSources(sources)
						if (!sources.includes('WEBSITE')) {
							setSourceUrl(null)
						}
					}}
				>
					{
						sourcesOptions.map((source) => {
							return (
								<Checkbox
									key={source.value}
									className={styles.inputTitle}
									value={source.value}
								>
									{source.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			{
				sources.includes('WEBSITE') &&
				<div>
					<p className={styles.title}>Source Info</p>
					<CustomSearchableSelectSecondary
						searchTask={searchSourceUrls}
						defaultOptions={sourceUrls}
						isLoading={isLoadingSourceUrls}
						valueIndex='sourceUrl'
						labelIndex='sourceUrl'
						placeholder='Select Source Info'
						onChange={async sourceUrl => setSourceUrl(sourceUrl)}
						onClear={async () => setSourceUrl(null)}
						value={sourceUrl}
						allowClear={true}
					/>
				</div>
			}
			<div>
				<p className={styles.title}>Creation Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={creationFromDate ? moment(creationFromDate) : null }
						onChange={(date) => setCreationFromDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						value={creationToDate ? moment(creationToDate) : null }
						onChange={(date) => setCreationToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(creationFromDate)}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Shipping Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={shippingFromDate ? moment(shippingFromDate) : null}
						onChange={(date) => setShippingFromDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						disabledDate={current => current && current.valueOf() < moment(shippingFromDate)}
						value={shippingToDate ? moment(shippingToDate) : null}
						onChange={(date) => setShippingToDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Transition Date Range</p>
				<div className={styles.inputFlex}>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='Start Date'
						value={transitionFromDate ? moment(transitionFromDate) : null}
						onChange={(date) => setTransitionFromDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
					<Input
						style={{ background: '#F7F7F7', borderRadius: 0 }}
						type='date'
						title='End Date'
						disabledDate={current => current && current.valueOf() < moment(transitionFromDate)}
						value={transitionToDate ? moment(transitionToDate) : null}
						onChange={(date) => setTransitionToDate(date?.toDate())}
						popupContainer={() => document.querySelector('.dropdown-overlay')}
						showTime={isDateTimeFormat && {
							use12Hours: true
						}}
						format={timeFormat}
					/>
				</div>
				{
					transitionFromDate && transitionToDate &&
					<div style={{ marginTop: 12 }}>
						<p className={styles.title}>Transition Statuses</p>
						<Checkbox
							className={styles.inputTitle}
							checked={areAllOrderStatusSelected}
							onChange={e => {
								const all = e.target.checked
								setAreAllOrderStatusSelected(all)
								if (all) {
									setTransitionStatuses(eCommerceStatusOptions.map(tab => tab.value))
								} else {
									setTransitionStatuses([])
								}
							}}
						>
							Select All
						</Checkbox>
						<Checkbox.Group
							style={{ marginTop: 12 }}
							className={styles.inputGroup}
							value={transitionStatuses}
							onChange={e => {
								if (e.length < eCommerceStatusOptions.length) {
									setAreAllOrderStatusSelected(false)
								} else {
									setAreAllOrderStatusSelected(true)
								}
								setTransitionStatuses(e)
							}}
							disabled={!transitionFromDate || !transitionToDate}
						>
							{
								eCommerceStatusOptions.map((option) => {
									return (
										<Checkbox
											key={option.value}
											className={styles.inputTitle}
											value={option.value}
										>
											{option.label}
										</Checkbox>
									)
								})
							}
						</Checkbox.Group>
					</div>
				}
			</div>
			{
				statuses.indexOf(orderStatuses.SALES_ORDER_ON_HOLD_STATUS) > -1 &&
				<div>
					<p className={styles.title}>Follow Up Date Range</p>
					<div className={styles.inputFlex}>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							type='date'
							title='Start Date'
							value={followUpFromDate ? moment(followUpFromDate) : null}
							onChange={(date) => setFollowUpFromDate(date?.toDate())}
							popupContainer={() => document.querySelector('.dropdown-overlay')}
							showTime={isDateTimeFormat && {
								use12Hours: true
							}}
							format={timeFormat}
						/>
						<Input
							style={{ background: '#F7F7F7', borderRadius: 0 }}
							type='date'
							title='End Date'
							disabledDate={current => current && current.valueOf() < moment(followUpFromDate)}
							value={followUpToDate ? moment(followUpToDate) : null}
							onChange={(date) => setFollowUpToDate(date?.toDate())}
							popupContainer={() => document.querySelector('.dropdown-overlay')}
							showTime={isDateTimeFormat && {
								use12Hours: true
							}}
							format={timeFormat}
						/>
					</div>
				</div>
			}
			<div>
				<p className={styles.title}>Invoice Range</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Starting Invoice'
						min={0}
						value={startingInvoice}
						onChange={(value) => {
							const formattedValue = +value
							setStartingInvoice(formattedValue)
							if (formattedValue > endingInvoice) {
								setEndingInvoice(formattedValue)
							}
						}}
					/>
					<NumberInput
						title='Ending Invoice'
						min={startingInvoice}
						value={endingInvoice}
						onChange={(value) => {
							const formattedValue = +value
							setEndingInvoice(formattedValue)
							if (formattedValue < startingInvoice) {
								setEndingInvoice(startingInvoice)
							}
							if (!startingInvoice) {
								setEndingInvoice(0)
							}
						}}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Order Value</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						min={0}
						value={minOrderValue}
						onChange={(value) => setMinOrderValue(+value)}
					/>
					<NumberInput
						title='Max'
						min={minOrderValue}
						value={maxOrderValue}
						onChange={(value) => {
							setMaxOrderValue(+value)
							if (!minOrderValue) {
								setMinOrderValue(0)
							}
						}}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Total Product Count</p>
				<div className={styles.inputFlex}>
					<NumberInput
						title='Min'
						min={0}
						value={minProductCount}
						onChange={(count) => {
							setMinProductCount(+count)
							if (count > maxProductCount) {
								setMaxProductCount(count)
							}
						}}
					/>
					<NumberInput
						title='Max'
						min={minProductCount}
						value={maxProductCount}
						onChange={(count) => {
							setMaxProductCount(+count)
							if (count < minProductCount) {
								setMaxProductCount(minProductCount)
							}
							if (!minProductCount) {
								setMinProductCount(0)
							}
						}}
					/>
				</div>
			</div>
			<div>
				<p className={styles.title}>Delivery Partner</p>
				<CustomSelectSecondary
					style={{ background: '#F7F7F7', borderRadius: 0 }}
					containerClassName={styles.select}
					title='Select Delivery Partner'
					optionLabelProp='label'
					loading={isLoadingDeliveryPartners}
					options={deliveryPartners}
					value={preferredDeliveryPartner}
					onChange={deliveryPartner => {
						setPreferredDeliveryPartner(deliveryPartner)
						setDeliveryOrderStatus(null)
					}}
					allowClear={true}
					optionRender={DeliveryPartnerOption}
					popupContainer={() => document.querySelector('.dropdown-overlay')}
				/>
			</div>
			<div>
				<p className={styles.title}>Delivery Partners Status</p>
				<CustomSearchableSelectWithTags
					title='Delivery Partners Status'
					selectedTitle='Selected Delivery Partners Statuses'
					style={{ width: '100%' }}
					disabled={!deliveryPartneStatusOptions.length}
					searchTask={async value => {
						const filteredData = deliveryPartneStatusOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
						return { data: filteredData }
					}}
					defaultOptions={deliveryPartneStatusOptions}
					labelIndex='label'
					valueIndex='value'
					placeholder='Select Delivery Partner Status'
					onChange={onDeliveryPartnerStatusSelected}
					onClear={() => setDeliveryOrderStatus(null)}
					value={deliveryOrderStatus}
					setValue={setDeliveryOrderStatus}
					areAllValuesSelected={areAllDeliveryOrderStatusesSelected}
					setAreAllValuesSelected={setAreAllDeliveryOrderStatusesSelected}
					onAreAllValuesSelectedChange={areAllOrderSourceSelected => {
						setSelectedDeliveryPartnerStatuses(areAllOrderSourceSelected ? deliveryPartneStatusOptions : [])
						setDeliveryOrderStatus(null)
					}}
					selectedValues={selectedDeliveryPartnerStatuses}
					setSelectedValues={setSelectedDeliveryPartnerStatuses}
					isOnChangeDropdownOpen={true}
					dropdownConfig={['label']}
					renderOption={({ data }) => {
						return (
							<div className={styles.option}>
								<div className={styles.customerNameContainer}>
									<Checkbox
										checked={selectedDeliveryPartnerStatuses.some(source => source.value === data.value)}
										onChange={() => onDeliveryPartnerStatusSelected({ data })}
										style={{ marginRight: 8 }}
									/>
									{data.label}
								</div>
							</div>
						)
					}}
					popupContainer={() => document.querySelector('.dropdown-overlay')}
				/>
			</div>
			<div>
				<p className={styles.title}>Pick Up Address</p>
				<CustomSearchableSelectSecondary
					searchTask={searchLocations}
					defaultOptions={locations}
					isLoading={isLoadingLocations}
					customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
					valueIndex='id'
					descriptionIndex='address'
					placeholder='Select Pick Up Address'
					onChange={location => {
						setLocation(location)
						getWarehouses()
					}}
					onClear={() => {
						setLocation(null)
						getWarehouses()
					}}
					value={location}
					allowClear={true}
				/>
			</div>
			{
				preferences.filterOrderByBranchEnabled &&
				<div>
					<p className={styles.title}>Branch</p>
					<CustomSearchableSelectSecondary
						searchTask={searchBranchLocations}
						defaultOptions={branchLocations}
						isLoading={isLoadingBranchLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Select Branch Address'
						onChange={location => {
							setBranchLocation(location)
							getBranches()
						}}
						onClear={() => {
							setBranchLocation(null)
							getBranches()
						}}
						value={branchLocation}
						allowClear={true}
					/>
				</div>
			}
			<div>
				<p className={styles.title}>By User</p>
				<CustomSelectSecondary
					title='Select Order Status'
					options={orderStatusOptions}
					value={orderStatus}
					onChange={setOrderStatus}
					allowClear
					popupContainer={() => document.querySelector('.dropdown-overlay')}
				/>
			</div>
			<div>
				<CustomSelectSecondary
					isLoading={isLoadingUsers}
					customLabel={(data) => data?.name || ''}
					valueIndex='id'
					title='Select User'
					options={users}
					onChange={setUser}
					value={user}
					allowClear={true}
					popupContainer={() => document.querySelector('.dropdown-overlay')}
					showSearch={true}
					filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
					filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
				/>
			</div>
			{
				companyDetails?.industryType === ecommerceIndustryType.HEALTH_CARE &&
				<CustomSelectSecondary
					title='Referred By Doctor'
					options={referrers}
					value={referrer}
					onChange={setReferrer}
					allowClear
					popupContainer={() => document.querySelector('.dropdown-overlay')}
					showSearch={true}
					filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
					filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
				/>
			}
			<div>
				<p className={styles.title}>Customer Tags</p>
				<CustomSearchableSelectWithTags
					title='Customer Tags'
					selectedTitle='Selected Customer Tags'
					style={{ width: '100%' }}
					disabled={!customerTagOptions.length || isCustomerTagOptionsLoading}
					searchTask={async value => {
						const filteredData = customerTagOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
						return { data: filteredData }
					}}
					defaultOptions={customerTagOptions}
					labelIndex='label'
					valueIndex='value'
					placeholder='Select Customer Tag'
					onChange={onCustomerTagSelected}
					onClear={() => setTag(null)}
					value={tag}
					setValue={setTag}
					areAllValuesSelected={areAllCustomerTagsSelected}
					setAreAllValuesSelected={setAreAllCustomerTagsSelected}
					onAreAllValuesSelectedChange={areAllOrderSourceSelected => {
						setSelectedCustomerTags(areAllOrderSourceSelected ? customerTagOptions : [])
						setTag(null)
					}}
					selectedValues={selectedCustomerTags}
					setSelectedValues={setSelectedCustomerTags}
					isOnChangeDropdownOpen={true}
					dropdownConfig={['label']}
					renderOption={({ data }) => {
						return (
							<div className={styles.option}>
								<div className={styles.customerNameContainer}>
									<Checkbox
										checked={selectedCustomerTags.some(tag => tag.value === data.value)}
										onChange={() => onCustomerTagSelected({ data })}
										style={{ marginRight: 8 }}
									/>
									{data.label}
								</div>
							</div>
						)
					}}
					popupContainer={() => document.querySelector('.dropdown-overlay')}
				/>
			</div>
			<div>
				<p className={styles.title}>Internal Notes</p>
				<Checkbox.Group
					style={{ marginTop: 15 }}
					className={styles.inputGroup}
					value={selectedInternalNotes}
					onChange={e => setSelectedInternalNotes(e)}
				>
					{
						availableAndUnavailableOptions.map((option) => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Discount (Product & Order)</p>
				<Checkbox.Group
					style={{ marginTop: 15 }}
					className={styles.inputGroup}
					value={selectedDiscounts}
					onChange={e => setSelectedDiscounts(e)}
				>
					{
						availableAndUnavailableOptions.map((option) => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Upsell Product</p>
				<Checkbox.Group
					style={{ marginTop: 15 }}
					className={styles.inputGroup}
					value={selectedUpsells}
					onChange={e => setSelectedUpsells(e)}
				>
					{
						availableAndUnavailableOptions.map((option) => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Advance Payments & Payments</p>
				<Checkbox.Group
					style={{ marginTop: 15 }}
					className={styles.inputGroup}
					value={selectedAdvancePayments}
					onChange={e => setSelectedAdvancePayments(e)}
				>
					{
						availableAndUnavailableOptions.map((option) => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div>
				<p className={styles.title}>Invoice Print</p>
				<Checkbox.Group
					style={{ marginTop: 15 }}
					className={styles.inputGroup}
					value={invoicePrintStatus}
					onChange={e => setInvoicePrintStatus(e)}
				>
					{
						invoicePrintStatusOptions.map((option) => {
							return (
								<Checkbox
									key={option.value}
									className={styles.inputTitle}
									value={option.value}
								>
									{option.label}
								</Checkbox>
							)
						})
					}
				</Checkbox.Group>
			</div>
			<div className={styles.buttons}>
				{
					isFilterEnabled() &&
						<Button
							className={`${styles.button} ${styles.resetButton}`}
							onClick={resetFilter}
						>
							<span className={styles.buttonText}>
								Reset All
							</span>
						</Button>
				}
				<Button
					type='primary'
					className={styles.button}
					disabled={!isFilterEnabled()}
					onClick={handleApply}
				>
					<span className={styles.buttonText}>
						Apply Filter
					</span>
				</Button>
			</div>
		</DropdownOverlay>
	)
}

export default EcommerceSalesOrderFilter
