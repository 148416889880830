import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { DeliveryPartners } from '../../services/api/delivery-partner'

const DeliveryAreaSelectXpressInTown = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingDivisions, setIsLoadingDivisions] = useState(false)
	const [isLoadingCities, setIsLoadingCities] = useState(false)
	const [isLoadingZones, setIsLoadingZones] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [divisions, setDivisions] = useState([])
	const [cities, setCities] = useState([])
	const [zones, setZones] = useState([])
	const [areas, setAreas] = useState([])

	useEffect(() => {
		getDivisions()
	}, [])

	useEffect(() => {
		if (!value?.division?.value) {
			setCities([])
			setZones([])
			setAreas([])
			return
		}
		getCities(value.division.value)
	}, [value?.division?.value])

	useEffect(() => {
		if (!value?.city?.value) {
			setZones([])
			setAreas([])
			return
		}
		getZones(value.city.value)
	}, [value?.city?.value])

	useEffect(() => {
		if (!value?.zone?.value) {
			setAreas([])
			return
		}
		getAreas(value.zone.value)
	}, [value?.zone?.value])

	const getDivisions = async () => {
		setIsLoadingDivisions(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryDivisions(deliveryPartnerId)
			const divisions = data?.length
				? data.map(division => ({
					...division,
					value: division.id,
					label: division.name
				}))
				: []
			setDivisions(divisions)
		} catch (e) {
			onChange({ ...value, division: null })
			setDivisions([])
			notification.error({
				message: 'Unable To Get Divisions',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDivisions(false)
		}
	}

	const getCities = async (divisionId) => {
		setIsLoadingCities(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryCities(deliveryPartnerId, { divisionId })
			const cities = data?.length
				? data.map(city => ({
					...city,
					value: city.id,
					label: city.name
				}))
				: []
			setCities(cities)
		} catch (e) {
			onChange({ ...value, city: null })
			setCities([])
			notification.error({
				message: 'Unable To Get Districts',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingCities(false)
		}
	}

	const getZones = async districtId => {
		setIsLoadingZones(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryZones(deliveryPartnerId, { districtId })
			const zones = data?.length
				? data.map(zone => ({
					...zone,
					value: zone.id,
					label: zone.name
				}))
				: []
			setZones(zones)
		} catch (e) {
			onChange({ ...value, zone: null })
			setZones([])
			notification.error({
				message: 'Unable To Get Upazilas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingZones(false)
		}
	}

	const getAreas = async upazilaId => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { upazilaId })
			const areas = data?.length
				? data.map(area => ({
					...area,
					value: area.id,
					label: area.name
				}))
				: []
			setAreas(areas)
		} catch (e) {
			onChange({ ...value, area: null })
			setAreas([])
			notification.error({
				message: 'Unable To Get Unions',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const onDivisionChange = async (_, option) => {
		onChange({
			...value,
			division: option ? { value: option.value, label: option.label } : null,
			city: null,
			zone: null,
			area: null
		})
	}

	const onCityChange = async (_, option) => {
		onChange({
			...value,
			city: option ? { value: option.value, label: option.label } : null,
			zone: null,
			area: null
		})
	}

	const onZoneChange = async (_, option) => {
		onChange({
			...value,
			zone: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'division',
					placeholder: 'Division',
					loading: isLoadingDivisions,
					options: divisions,
					value: value?.division || null,
					onChange: onDivisionChange,
					onClear: () => onDivisionChange(),
					style: { width: '25%' }
				},
				{
					id: 'city',
					placeholder: 'District',
					loading: isLoadingCities,
					options: cities,
					value: value?.city || null,
					onChange: onCityChange,
					onClear: () => onCityChange(),
					style: { width: '25%' }
				},
				{
					id: 'zone',
					placeholder: 'Upazila',
					loading: isLoadingZones,
					options: zones,
					value: value?.zone || null,
					onChange: onZoneChange,
					onClear: () => onZoneChange(),
					style: { width: '25%' }
				},
				{
					id: 'area',
					placeholder: 'Union',
					loading: isLoadingAreas,
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '25%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectXpressInTown.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectXpressInTown.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectXpressInTown
